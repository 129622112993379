<template>
    <v-container
        fluid
        style="max-width: 550px; justify-content: center; display: flex;"
        align-items="center"
    >
        <v-row
            v-if="loading"
            justify="center"
        >
            <v-col
                cols="12"
            >
                <div class="d-flex justify-center">
                    <div
                        class="d-flex flex-column "
                    >
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="#0060e6"
                            indeterminate
                        />
                        <div>Logger ind...</div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row
            v-if="!verifying"
            class="justify-center"
            style="margin-top: 500px;"
        >
            <v-col cols="12">
                <h1 style="font-size: small">
                    Indtast venligst din e-mailadresse
                </h1>
            </v-col>
            <v-col cols="8">
                <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="globalEmailValidator(email)"
                    @keydown.enter="initializeTwoFactorAuthentication()"
                /> 
            </v-col>
            <v-col cols="4" />
            <v-col cols="8">
                <v-btn
                    color="#75ad40"
                    style="margin-top: 0px; width: 100%;"
                    :disabled="working || globalEmailValidator(email).length > 0"
                    @click="initializeTwoFactorAuthentication()"
                >
                    Send valideringskode
                </v-btn>
            </v-col>
            <v-col cols="4" />
        </v-row>

        <v-row
            v-if="verifying"
            class="justify-center"
            style="margin-top: 500px;"
        >
            <v-col cols="12">
                <h1 style="font-size: small">
                    Indtast venligst den 6-cifrede kode du har modtaget på {{ email }}
                </h1>
            </v-col>
            <v-col
                cols="8"
            >
                <v-text-field
                    v-model="twoFactorCode"
                    label="Kode"
                    @keydown.capture="validateTwoFactorCode()"
                />
            </v-col>
            <v-col cols="4" />
            <v-col cols="8">
                <v-btn
                    color="#75ad40"
                    style="margin-top: 0px; width: 100%;"
                    :disabled="twoFactorCode.length != 6"
                    @click="validateTwoFactorCode()"
                >
                    Videre
                </v-btn>
            </v-col>
            <v-col cols="4" />
            <v-col
                v-if="showInputError"
                cols="8"
            >
                <v-alert
                    v-model="showInputError"
                    type="error"
                    dismissible
                    border="left"
                    elevation="2"
                    colored-border
                    icon="mdi-alert-circle"
                    click:close="showInputError = false"
                >
                    {{ errorMessage }}
                </v-alert>
            </v-col>
            <v-col cols="4" />
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data() {
        return {
            working: false,

            verifying: false,
            showInputError: false,
            twoFactorCode: '',
            errorMessage: 'Forkert kode',
            loading: false,
            email: '',
            valid: false
        }
    },
    methods: {
        validateTwoFactorCode(){
            if(this.twoFactorCode.length != 6){
                return
            }
            this.ValidateUserEmailCode({
                jwtToken: this.token,
                email: this.email,
                code: this.twoFactorCode
            })
                .then(result => {
                    if(result != null && result.success && result.customer != null){
                        this.LogIn()
                    }
                    else if(result.customerAlreadyExists){
                        this.showInputError = true
                        this.errorMessage = 'Der findes allerede en kunde med denne email. Kontakt venligst support'
                        return
                    } else if(result == null || result.validated == false){
                        this.showInputError = true
                        this.errorMessage = 'Forkert kode'
                        return
                    } else {
                        this.requirePrivateCustomerEmail = false
                        this.newCustomer = true
                        this.verifying = false

                    }
                })
        },
        initializeTwoFactorAuthentication(){
            this.working = true
            if(this.globalEmailValidator(this.email).length == 0){
                this.CreateValidateUserEmailCode({
                    jwtToken: this.token,
                    email: this.email,
                })
                this.verifying = true
                this.working = false
            }
        },
        ...mapActions({
            LogInCriiptoPrivate: 'LogInCriiptoPrivate',
            GetCustomerPrivateByCriiptoIdentification: 'GetCustomerPrivateByCriiptoIdentification',
            LogOut: 'LogOut',
            CreateValidateUserEmailCode: 'CreateValidateUserEmailCode',
            ValidateUserEmailCode: 'ValidateUserEmailCode',
            GetSystemSetting: 'GetSystemSetting'
        }),
        ...mapMutations({
            setSelectedPrivateCustomer: 'setSelectedPrivateCustomer'
        }),
    }
}
</script>